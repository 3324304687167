<template>
  <div>
    <div class="filter-box">
      <ul class="filter-ul">
        <select-picker
          v-if="$route.params.i !== '0'"
          :data="filterData.selleUserId"
          @toTree="toTree"
        />
        <!-- <select-picker :data="filterData.cooperationStatus" /> -->
      </ul>
    </div>
    <div class="bottom-postion">
      <cube-button
        :outline="true"
        class="btn btns"
        @click="reset"
      >
        重置
      </cube-button>
      <cube-button
        class="btn btns"
        @click="confirm"
      >
        确认
      </cube-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import selectPicker from '_c/picker/select';

export default {
  components: {
    selectPicker
  },
  data() {
    return {
      filterData: {
        selleUserId: {
          title: '负责销售',
          name: '',
          value: '',
          dap: false,
          typeName: 'selleUserId'
        },
        // cooperationStatus: {
        //   title: '状态',
        //   name: '',
        //   value: '',
        //   options: []
        // }
      }
    };
  },
  computed: {
    ...mapGetters('cust', ['filterObj']),
    ...mapGetters(['dictList'])
  },
  mounted() {
    this.getSellData();
    this.getOptions();
  },
  methods: {
    ...mapMutations('cust', ['CUST_FILTER']),
    ...mapMutations(['CLEAR_FILTER']),
    confirm() {
      this.cache();
      this.$router.go(-1);
    },
    reset() {
      for (const i in this.filterData) {
        this.filterData[i].name = '';
        this.filterData[i].value = '';
      }
      this.CLEAR_FILTER('cust');
    },
    cache() {
      const filterData = {};
      for (const i in this.filterData) {
        filterData[i] = {
          name: this.filterData[i].name,
          value: this.filterData[i].value
        };
      }

      this.CUST_FILTER(filterData);
    },
    toTree(typeName) {
      this.cache();
      this.$router.push(
        {
          name: 'tree',
          params: {
            routerName: this.$route.name,
            typeName
          }
        },
      );
    },
    getSellData() {
      if (JSON.stringify(this.filterObj) !== '{}') {
        for (const i in this.filterObj) {
          if (!this.filterData[i]) continue
          this.filterData[i].name = this.filterObj[i] ? this.filterObj[i].name : '';
          this.filterData[i].value = this.filterObj[i] ? this.filterObj[i].value : '';
        }
      }
    },
    getOptions() {
      // this.filterData.cooperationStatus.options = this.dictList.cooperationStatus;
    }
  }
};

</script>

<style lang="scss" scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped  src="@/assets/css/components/filter.scss"></style>
